import { Avatar, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { reportApi } from '../../../../api/report';
import Table from '../../../../components/Table';
import { DialogBox, StyledLabelTypograph, StyledTypograph } from './style';
import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { membersApi } from '../../../../api/members';
import { User } from '../../../../models/user';
import { useNavigate } from 'react-router-dom';
type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  query: {
    key: string;
    entityType: string;
    entityId: string;
    dayIndex?: number;
  };
};


function DialogMember(props: Props) {
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [goodMembers, setGoodMembers] = useState<User[]>([]);
  const [mediumMembers, setMediumMembers] = useState<User[]>([]);
  const [badMembers, setBadMembers] = useState<User[]>([]);
  useEffect(() => {
    if (props?.query?.entityId && props?.query?.key) {
      reportApi
        .getSummaryReportByMembersDetailData(
          props?.query?.key,
          props?.query.entityType,
          props.query?.entityId,
          props?.query?.dayIndex
        )
        .then((data) => {
          if ((data?.goodMembers?.length || -1) > 0) {
            const requestGoodMembers = data?.goodMembers.map(
              async (item: any) => {
                return await membersApi.getUserInfo(item);
              }
            );
            Promise.all(requestGoodMembers).then((goodMembers) => {
              setGoodMembers(goodMembers || []);
            });
          } else {
            setGoodMembers([]);
          }

          if ((data?.goodMembers?.length || -1) > 0) {
            const requestMediumMembers = data?.mediumMembers.map(
              async (item: any) => {
                return await membersApi.getUserInfo(item);
              }
            );
            Promise.all(requestMediumMembers).then((mediumMembers) => {
              setMediumMembers(mediumMembers || []);
            });
          } else {
            setMediumMembers([]);
          }

          if ((data?.badMembers?.length || -1) > 0) {
            const requestBadMembers = data?.badMembers.map(
              async (item: any) => {
                return await membersApi.getUserInfo(item);
              }
            );
            Promise.all(requestBadMembers).then((badMembers) => {
              setBadMembers(badMembers || []);
            });
          } else {
            setBadMembers([]);
          }
        });
    }
  }, [props?.query]);
  return (
    <>
      <Dialog onClose={props?.onClose} open={props?.open} maxWidth="md">
        <DialogBox>
          <StyledTypograph
            align="center"
            variant="h4"
            sx={{
              fontWeight: 'bold',
            }}
            gutterBottom
          >
            {props?.title}
          </StyledTypograph>
          <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  centered
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={
                      <StyledLabelTypograph>Good members</StyledLabelTypograph>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <StyledLabelTypograph>
                        Medium members
                      </StyledLabelTypograph>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <StyledLabelTypograph>Bad members</StyledLabelTypograph>
                    }
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Table
                  onRowClick={(e, rowdata: any) => {
                    navigate(`/admin/members/member-report/${rowdata?.id}`);
                  }}
                  dataSource={goodMembers}
                  columns={[
                    {
                      dataKey: 'idx',
                      key: 'idx',
                      label: 'Index',
                      render: (d, row, idx) => {
                        return <span>{idx + 1}</span>;
                      },
                    },
                    {
                      dataKey: 'avatar',
                      key: 'avatar',
                      label: 'Avatar',
                      render: (data, rowdata, idx) => {
                        return (
                          <Avatar
                            alt="Remy Sharp"
                            src={rowdata?.photoURL || ''}
                          />
                        );
                      },
                    },
                    {
                      dataKey: 'name',
                      key: 'name',
                      label: 'Name',
                      render: (data, rowdata, idx) => {
                        return rowdata?.aliasName || rowdata?.displayName;
                      },
                    },
                    {
                      dataKey: 'email',
                      key: 'email',
                      label: 'Email',
                    },
                  ]}
                  rowKey={(row) => row.email!}
                />
              </TabPanel>
              <TabPanel value="2">
                <Table
                  onRowClick={(e, rowdata: any) => {
                    navigate(`/admin/members/member-report/${rowdata?.id}`);
                  }}
                  dataSource={mediumMembers}
                  columns={[
                    {
                      dataKey: 'idx',
                      key: 'idx',
                      label: 'Index',
                      render: (d, row, idx) => {
                        return <span>{idx + 1}</span>;
                      },
                    },
                    {
                      dataKey: 'avatar',
                      key: 'avatar',
                      label: 'Avatar',
                      render: (data, rowdata, idx) => {
                        return (
                          <Avatar
                            alt="Remy Sharp"
                            src={rowdata?.photoURL || ''}
                          />
                        );
                      },
                    },
                    {
                      dataKey: 'name',
                      key: 'name',
                      label: 'Name',
                      render: (data, rowdata, idx) => {
                        return rowdata?.aliasName || rowdata?.displayName;
                      },
                    },
                    {
                      dataKey: 'email',
                      key: 'email',
                      label: 'Email',
                    },
                  ]}
                  rowKey={(row) => row.email!}
                />
              </TabPanel>
              <TabPanel value="3">
                <Table
                  onRowClick={(e, rowdata: any) => {
                    navigate(`/admin/members/member-report/${rowdata?.id}`);
                  }}
                  dataSource={badMembers}
                  columns={[
                    {
                      dataKey: 'idx',
                      key: 'idx',
                      label: 'Index',
                      render: (d, row, idx) => {
                        return <span>{idx + 1}</span>;
                      },
                    },
                    {
                      dataKey: 'avatar',
                      key: 'avatar',
                      label: 'Avatar',
                      render: (data, rowdata, idx) => {
                        return (
                          <Avatar
                            alt="Remy Sharp"
                            src={rowdata?.photoURL || ''}
                          />
                        );
                      },
                    },
                    {
                      dataKey: 'name',
                      key: 'name',
                      label: 'Name',
                      render: (data, rowdata, idx) => {
                        return rowdata?.aliasName || rowdata?.displayName;
                      },
                    },
                    {
                      dataKey: 'email',
                      key: 'email',
                      label: 'Email',
                    },
                  ]}
                  rowKey={(row) => row.email!}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </DialogBox>
      </Dialog>
    </>
  );
}
export default DialogMember;
