import { Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";

export const MedioButton = styled(Button)({
  padding: "5px 16px",
  textTransform: "none",
  fontFamily: "inherit",
  minWidth: "90px"

});
export const StyledTypograph = styled(Typography)({
    fontFamily: "quicksand",
    fontSize: "17px",
    margin: "0px 0px 20px 0px",
})


export const StyledLabelTypograph = styled(Typography)({
  fontFamily: "quicksand",
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "none",
})

export const DialogBox = styled(Box)({
    padding: "30px 0px 20px 0px",
    width: "700px",
    maxHeight: "450px"
})
