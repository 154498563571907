import * as React from 'react';
import {
  StyledButton,
  StyledButtonArrowDropDownIcon,
  StyledButtonArrowDropUpIcon,
  StyledPopover,
} from './style';
import { Box } from '@mui/material';
import ExpansionTreeView from './tree-view';
import { useEffect } from 'react';
import { companyApi } from '../../../api/company';
import { useDateRangeQuery } from '../../../hooks/date-range';

import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
type Props = {
  label?: string;
  nodeId?: string;
  path?: string[];
  isNavigate?: boolean;
  onChangeId?: (id: string) => void;
  onChangeName?: (name: string) => void;
  isUseForReport?: boolean;
};
export default function PopoverSelect(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [dataSelected, setDataSelected] = React.useState<string>(
    props?.label || ''
  );
  const [nodeId, setNodeId] = React.useState<string>(props?.nodeId || '');
  const [path, setPath] = React.useState<string[]>(props?.path || []);
  const dateRange = useDateRangeQuery();
  const companyInfo = useSelector((state: RootState) => state.company.company);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    if (props.label) {
      setDataSelected(props?.label);
    }
  }, [props.label]);
  useEffect(() => {
    if (props.nodeId) {
      setNodeId(props?.nodeId);
    }
  }, [props.nodeId]);
  useEffect(() => {
    if (props.path) {
      setPath(props?.path);
    }
  }, [props.path]);

  return (
    <Box>
      <StyledButton
        sx={{
          border: !open ? '1px solid rgba(0, 0, 0, 0.23)' : '1px solid #0280c5',
        }}
        variant="outlined"
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <span>{dataSelected || 'Select group'}</span>
          {!open ? (
            <StyledButtonArrowDropDownIcon />
          ) : (
            <StyledButtonArrowDropUpIcon />
          )}
        </Box>
      </StyledButton>
      <StyledPopover
        defaultValue={'group1'}
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ExpansionTreeView
          isUseForReport={props?.isUseForReport}
          isNavigate={props.isNavigate}
          path={path}
          defaultNodeId={nodeId}
          onChangeValue={(id) => {
            props?.onChangeId?.(id);
            setAnchorEl(null);
            if (!props.isNavigate && id !== companyInfo?.id) {
              if (companyInfo?.id) {
                companyApi
                  .getGroupDetail(companyInfo?.id, id, {
                    startDate: dateRange[0],
                    endDate: dateRange[1],
                  })
                  .then((value) => {
                    if (value) {
                      setDataSelected(value.name);
                      console.log(value?.name, 'name');

                      props?.onChangeName?.(value?.name);
                    }
                  });
              }
            }
          }}
        ></ExpansionTreeView>
      </StyledPopover>
    </Box>
  );
}
