import { Company } from "../../models/company";
import { CompanyActionEnum, CompanyActionTypes } from "./action";

type CompanyState = {
  company?: Company;
};

const initState = Object.freeze<CompanyState>({});

export default function (
  state = initState,
  action: CompanyActionTypes
): CompanyState {
  switch (action.type) {
    case CompanyActionEnum.SET_COMPANY: {
      return {
        ...state,
        company: action.payload,
      };
    }
    case CompanyActionEnum.RESET: {
      return initState;
    }
    default: {
      return state;
    }
  }
}
