import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTypography = styled(Typography)({
    fontFamily: "inherit",
    fontSize: "12px",
    marginTop: "10px"
})
export const StyledTextTypography = styled(Typography)({
    fontFamily: "inherit",
    fontSize: "14px",
    backgroundColor:"#e7f7f6",
    padding: "10px 0px",
    borderRadius: "5px",
    marginTop: "4px",
    border: "1px solid #80808030",
})