import { mdiAccountDetails, mdiDotsVertical } from '@mdi/js';
import { Assessment, Delete, Edit } from '@mui/icons-material';
import {
  IconButton,
  MenuList,
  Popover,
  PopoverPosition,
  SvgIcon,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { companyApi } from '../../../../api/company';
import { membersApi } from '../../../../api/members';
import CustomMenuItem from '../../../../components/CustomMenuItem';
import Empty from '../../../../components/Empty';
import Loading from '../../../../components/loading';
import Table from '../../../../components/Table';
import { StyledTableCell } from '../../../../components/Table/styled';
import { getDataGroups } from '../../../../helper/group';
import { getPercentColor } from '../../../../helper/member';
import { useDateRangeQuery } from '../../../../hooks/date-range';
import { useShowDialog } from '../../../../hooks/dialog';
import { Group, Member } from '../../../../models/members';
import CreateOrUpdateGroupDialog from '../../../../popups/CreateOrUpdateGroupDialog';
import { RootState } from '../../../../redux';
import { GroupsActions } from '../../../../redux/groups';
import { StyledTypography } from './styled';

type Props = {
  group: Group;
  parentGroup?: Group;
  loading?: boolean;
  onReload?: () => void;
};

export default function GroupDetailTable(props: Props) {
  if (!props.group.subGroups?.groups?.length && !props.group.members?.length) {
    return (
      <Empty
        style={{ marginTop: 16 }}
        text={
          <p>
            This group is empty. You can <b>create sub-group</b>, <b>add</b> or{' '}
            <b>import members</b>.
          </p>
        }
      />
    );
  }

  const Component = props.group.subGroups?.groups?.length
    ? GroupTable
    : MemberTable;
  return <Component {...props} />;
}

function GroupTable(props: Props) {
  const [optionParam, setOptionParam] = useState<
    | {
        anchorPossition?: PopoverPosition;
        anchorEl?: HTMLElement;
        supgroup: Group;
      }
    | undefined
  >();
  const [editingGroup, setEditingGroup] = useState<Group | undefined>();
  const showConfirm = useShowDialog();
  const naviagte = useNavigate();
  const dateRange = useDateRangeQuery();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const companyInfo = useSelector((state: RootState) => state.company.company);

  const average =
    (props.group.subGroups?.groups
      ?.map((a) => a.percentPracticed)
      .reduce((a, b) => a + b, 0) ?? 0) /
    (props.group.subGroups?.groups?.length || 1);

  async function deleteEmptyGroup(groupId: string) {
    if (companyInfo?.id) {
      await companyApi.deleteGroup(companyInfo.id, groupId);
    }
  }
  const deleteGroup = async (groupId: string) => {
    if (companyInfo?.id) {
      const dataGroup = await companyApi.getGroupDetail(
        companyInfo.id,
        groupId,
        {
          startDate: dateRange[0],
          endDate: dateRange[1],
        }
      );
      if (dataGroup) {
        if (
          dataGroup.members?.length === 0 &&
          dataGroup.subGroups?.groups?.length === 0
        ) {
          await deleteEmptyGroup(groupId);
        } else if ((dataGroup.members?.length || 0) > 0) {
          let memberIds: string[] = [];
          dataGroup.members?.forEach((item) => {
            memberIds.push(item.id);
          });
          await membersApi.deleteMembers(companyInfo?.id, groupId, memberIds);
          await deleteEmptyGroup(groupId);
        } else {
          if (
            (dataGroup.subGroups?.groups?.length || 0) > 0 &&
            dataGroup.subGroups
          ) {
            const length = dataGroup.subGroups?.groups?.length || 0;
            for (let i = 0; i < length; i++) {
              if(dataGroup?.subGroups?.groups){
                await deleteGroup((dataGroup?.subGroups?.groups[i] as Group).id);
              }

              if (i == length - 1) {
                await deleteEmptyGroup(groupId);
              }
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Box>
        <Loading open={isLoading} />
        <Table
          loading={props.loading}
          columns={[
            {
              key: 'name',
              dataKey: 'name',
              label: 'Name',
            },
            {
              key: 'description',
              dataKey: 'description',
              label: 'Description',
            },
            {
              key: 'numberOfMembers',
              dataKey: 'numberOfMembers',
              label: 'Members',
            },
            {
              key: 'numberOfInstalledMembers',
              dataKey: 'numberOfInstalledMembers',
              label: 'Installed Members',
            },
            {
              key: 'percentPracticed',
              dataKey: 'percentPracticed',
              label: 'Completed practice',
              render: (data, row) => {
                return (
                  <span
                    style={{
                      color: getPercentColor(data),
                      fontWeight: 600,
                    }}
                  >
                    {data}%
                  </span>
                );
              },
            },
            {
              key: 'createdAt',
              dataKey: 'createdAt',
              label: 'Created at',
              render: (data) => moment(data).format('MMM DD, YYYY'),
            },
            {
              key: 'action',
              dataKey: 'id',
              label: '',
              render: (id: number, row) => (
                <div>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOptionParam({
                        anchorEl: e.currentTarget,
                        supgroup: row,
                      });
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <path d={mdiDotsVertical} />
                    </SvgIcon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          dataSource={props.group.subGroups?.groups ?? []}
          onRowClick={(e, row, idx) => {
            naviagte({
              pathname: `../groups/${row.id}`,
            });
          }}
          onRowContext={(e, row) => {
            e.preventDefault();
            setOptionParam({
              anchorPossition: {
                left: e.clientX,
                top: e.clientY,
              },
              anchorEl: undefined,
              supgroup: row,
            });
          }}
          rowKey={(row) => row.id.toString()}
          // tail={
          //   props.group.subGroups?.groups?.length ? (
          //     <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
          //       <StyledTableCell />
          //       <StyledTableCell />
          //       <StyledTableCell>
          //         Total: <b>{props.group?.subGroups?.totalMembers}</b>
          //       </StyledTableCell>
          //       <StyledTableCell>
          //         Avg: <b>{Math.round(average)}%</b>
          //       </StyledTableCell>
          //       <StyledTableCell />
          //       <StyledTableCell />
          //     </TableRow>
          //   ) : undefined
          // }
        />

        <Box sx={{ my: '20px' }}>
          <StyledTypography variant="h3">
            {' '}
            Total members: {props.group?.subGroups?.totalMembers}
          </StyledTypography>
          <StyledTypography variant="h3">
            {' '}
            Total installed members: {props.group?.subGroups?.totalInstalledMembers}
          </StyledTypography>
          <StyledTypography variant="h3">
            {' '}
            Average completed practics:{' '}
            {Math.round(average)}%
          </StyledTypography>
        </Box>
        <Popover
          anchorReference={
            optionParam?.anchorEl ? 'anchorEl' : 'anchorPosition'
          }
          anchorEl={optionParam?.anchorEl}
          BackdropProps={{
            onContextMenu: (e) => {
              e.preventDefault();
              e.currentTarget.click();
            },
            sx: {
              backgroundColor: 'transparent',
            },
          }}
          anchorPosition={optionParam?.anchorPossition}
          transformOrigin={{
            horizontal: !optionParam?.anchorEl ? 'left' : 'right',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: optionParam?.anchorEl ? 'left' : 'right',
            vertical: 'center',
          }}
          open={!!optionParam}
          onClose={() => setOptionParam(undefined)}
        >
          <MenuList sx={{ p: 0 }}>
            <CustomMenuItem
              icon={<Edit fontSize="inherit" />}
              text="Edit"
              onClick={() => {
                setOptionParam(undefined);
                setTimeout(() => {
                  setEditingGroup(optionParam?.supgroup);
                }, 200);
              }}
            />
            <CustomMenuItem
              icon={<Delete fontSize="inherit" />}
              text="Delete"
              color="#c60c0c"
              onClick={() => {
                setOptionParam(undefined);
                setTimeout(() => {
                  showConfirm({
                    okText: 'Delete',
                    content: (
                      <div>
                        <div>
                          <b>
                            Warning: This group has many members or many
                            subGroup.{' '}
                          </b>
                        </div>
                        <div>
                          This action cannot revert, do you want to delete group
                          '<b>{optionParam?.supgroup?.name}</b>'?
                        </div>
                      </div>
                    ),
                    title: 'Caution',
                    okColor: 'error',
                    onOk() {
                      if (optionParam?.supgroup.id) {
                        setIsLoading(true);
                        deleteGroup(optionParam?.supgroup.id)
                          .then((res) => {
                            setIsLoading(false);

                            props?.onReload?.();
                            enqueueSnackbar(`Delete group succcess !`, {
                              variant: 'success',
                            });
                            if (companyInfo?.id) {
                              getDataGroups(companyInfo?.id).then((data) => {
                                if (data) {
                                  dispatch(GroupsActions.setDataGroup(data));
                                }
                              });
                            }
                          })
                          .catch((err) => {
                            setIsLoading(false);
                            console.log(err);
                            props?.onReload?.();
                            enqueueSnackbar(`${err.error.message}`, {
                              variant: 'error',
                            });
                            if (companyInfo?.id) {
                              getDataGroups(companyInfo?.id).then((data) => {
                                if (data) {
                                  dispatch(GroupsActions.setDataGroup(data));
                                }
                              });
                            }
                          });
                      }
                    },
                  });
                }, 200);
              }}
            />
          </MenuList>
          <Loading open={isLoading} />
        </Popover>
        {companyInfo?.id && (
          <CreateOrUpdateGroupDialog
            open={!!editingGroup}
            onClose={() => setEditingGroup(undefined)}
            parentGroup={props.group}
            group={editingGroup}
            companyId={companyInfo?.id}
            onSuccess={() => {
              if (companyInfo?.id) {
                getDataGroups(companyInfo?.id).then((data) => {
                  if (data) {
                    dispatch(GroupsActions.setDataGroup(data));
                  }
                });
                props?.onReload?.();
              }
            }}
          />
        )}
      </Box>
    </>
  );
}

function MemberTable(props: Props) {
  const [optionParam, setOptionParam] = useState<
    | {
        anchorPossition?: PopoverPosition;
        anchorEl?: HTMLElement;
        member: Member;
      }
    | undefined
  >();
  const showConfirm = useShowDialog();
  const naviagte = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const companyInfo = useSelector((state: RootState) => state.company.company);

  // Delete Member
  const deleteMember = (memberId: string, fullName: string) => {
    if (companyInfo?.id) {
      membersApi
        .deleteMembers(companyInfo?.id, props.group.id, [memberId])
        .then(() => {
          props?.onReload?.();
          enqueueSnackbar(`Delete member ${fullName} succcess !`, {
            variant: 'success',
          });
        })
        .catch((err) => {
          enqueueSnackbar(`${err}`, { variant: 'error' });
        });
    }
  };
  const [rows, setRows] = useState<Member[]>(props?.group?.members || []);
  const [totalMember, setTotalMember] = useState<number>(0);
  const [numberCompletedMember, setNumberCompletedMember] = useState<number>(0);

  // State panigation
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if ((props.group.members?.length || 0) > 0) {
      const newRows: Member[] | undefined = props?.group?.members?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      if (newRows) {
        setRows(newRows);
      }
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (props.group.members) {
      setTotalMember(props.group.members.length);
      let a: number = 0;
      props.group.members.forEach((item: Member) => {
        if (item.percentPracticed === 1) {
          a++;
        }
      });
      setNumberCompletedMember(a);
    }
  }, [props.group.members]);

  return (
    <>
      <Table
        loading={props.loading}
        columns={[
          {
            key: 'fullName',
            dataKey: 'fullName',
            label: 'Name',
          },
          {
            key: 'email',
            dataKey: 'email',
            label: 'Email',
          },
          {
            key: 'percentPracticed',
            dataKey: 'percentPracticed',
            label: 'Completed practice',
            render: (data) => {
              return (
                <span
                  style={{
                    color: getPercentColor(data),
                  }}
                >
                  {data}%
                </span>
              );
            },
          },
          {
            key: 'installed',
            dataKey: 'installed',
            label: 'Installed',
            render: (id: number, row) => (
              <span>{row?.lastLogined ? 'Yes' : 'No'}</span>
            ),
          },
          {
            key: 'lastLogined',
            dataKey: 'lastLogined',
            label: 'Last loged in',
            render: (data) => data && moment(data).fromNow(),
          },
          {
            key: 'action',
            dataKey: 'id',
            label: '',
            render: (id: number, row) => (
              <div>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOptionParam({
                      anchorEl: e.currentTarget,
                      member: row,
                    });
                  }}
                >
                  <SvgIcon fontSize="small">
                    <path d={mdiDotsVertical} />
                  </SvgIcon>
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={rows ?? []}
        onRowClick={(e, row, idx) => {
          naviagte({
            pathname: `../member-report/${row.userId}`,
          });
        }}
        onRowContext={(e, row) => {
          e.preventDefault();
          setOptionParam({
            anchorPossition: {
              left: e.clientX,
              top: e.clientY,
            },
            anchorEl: undefined,
            member: row,
          });
        }}
        rowKey={(row) => row.id.toString()}
        pagination={{
          // count: 10,
          // page: 5,
          // rowsPerPage: 10,
          // rowsPerPageOptions: [],
          // onPageChange: (e, page) => {
          //   console.log(page);
          // },
          rowsPerPageOptions: [5, 10, 25],
          // component: 'div',
          count: props?.group?.members?.length || 0,
          rowsPerPage: rowsPerPage,
          page: page,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
      />
      <Box sx={{ my: '20px' }}>
        <StyledTypography variant="h3">
          {' '}
          Total members: {totalMember}
        </StyledTypography>
        <StyledTypography variant="h3">
          {' '}
          Average completed practics:{' '}
          {((numberCompletedMember / totalMember) * 100).toFixed(0)}%
        </StyledTypography>
      </Box>
      <Popover
        anchorReference={optionParam?.anchorEl ? 'anchorEl' : 'anchorPosition'}
        anchorEl={optionParam?.anchorEl}
        BackdropProps={{
          onContextMenu: (e) => {
            e.preventDefault();
            e.currentTarget.click();
          },
          sx: {
            backgroundColor: 'transparent',
          },
        }}
        anchorPosition={optionParam?.anchorPossition}
        transformOrigin={{
          horizontal: !optionParam?.anchorEl ? 'left' : 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: optionParam?.anchorEl ? 'left' : 'right',
          vertical: 'center',
        }}
        open={!!optionParam}
        onClose={() => setOptionParam(undefined)}
      >
        <MenuList sx={{ p: 0 }}>
          <CustomMenuItem
            icon={<Assessment fontSize="inherit" />}
            text="View user's report"
            onClick={() => {
              setOptionParam(undefined);
              setTimeout(() => {
                naviagte({
                  pathname: `../member-report/${optionParam?.member.id}/${optionParam?.member.userId}`,
                });
              }, 200);
            }}
          />
          <CustomMenuItem
            icon={
              <SvgIcon fontSize="inherit">
                <path d={mdiAccountDetails} />
              </SvgIcon>
            }
            text="View user's informations"
            onClick={() => {
              setOptionParam(undefined);
              setTimeout(() => {
                naviagte({
                  pathname: `../member-report/${optionParam?.member.id}/${optionParam?.member.userId}`,
                });
              }, 200);
            }}
          />
          <CustomMenuItem
            icon={<Delete fontSize="inherit" />}
            text="Remove member"
            color="#c60c0c"
            onClick={() => {
              setOptionParam(undefined);
              setTimeout(() => {
                showConfirm({
                  okText: 'Delete',
                  content: (
                    <div>
                      <div>This action cannot revert,</div>
                      <div>
                        do you want to delete member '
                        <b>{optionParam?.member.fullName}</b>'?
                      </div>
                    </div>
                  ),
                  title: 'Caution',
                  okColor: 'error',
                  onOk() {
                    if (optionParam?.member?.id) {
                      deleteMember(
                        optionParam?.member?.id,
                        optionParam?.member.fullName
                      );
                    }
                  },
                });
              }, 200);
            }}
          />
        </MenuList>
      </Popover>
    </>
  );
}
