import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PageContainer,
  PageContentContainer,
  PageHeaderTitle,
  PageTitle,
} from '../../../../components/styled';
import useQuery from '../../../../hooks/use-query';
import DateRangePicker from '../../../../components/DateRangePicker';
import { Group } from '../../../../models/members';
import { companyApi } from '../../../../api/company';
import { TableHeader } from './styled';
import Button from '../../../../components/Button';
import { createSearchQuery } from '../../../../utils/query';
import { useDateRangeQuery } from '../../../../hooks/date-range';
import CreateOrUpdateGroupDialog from '../../../../popups/CreateOrUpdateGroupDialog';
import GroupDetailTable from '../components/GroupDetailTable';
import { GroupsActions } from '../../../../redux/groups';
import { getDataGroups } from '../../../../helper/group';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux';

export default function GroupListPage() {
  const { pathname } = useLocation();
  const naviagte = useNavigate();
  const query = useQuery();
  const [list, setList] = useState<{
    totalMembers: number;
    totalInstalledMembers: number;
    groups: Group[];
  }>({
    totalMembers: 0,
    totalInstalledMembers: 0,
    groups: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const companyInfo = useSelector((state: RootState) => state.company.company);
  const dateRange = useDateRangeQuery();
  useEffect(() => {
    document.title = 'Members - Mindfully Partner Portal';
  }, []);

  function updateURL(params: {
    keyword?: string;
    page?: number;
    limit?: number;
    start_date?: string;
    end_date?: string;
  }) {
    naviagte({
      pathname,
      search: createSearchQuery({ ...query, ...params }),
    });
  }

  const fetchData = useCallback(() => {
    if (companyInfo?.id) {
      setLoading(true);
      companyApi
        .getGroups(companyInfo?.id, {
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
        .then((data:any) => {
          setList({
            totalInstalledMembers: data?.totalInstalledMembers,
            totalMembers: data?.totalMembers,
            groups: data?.groups,
          })
        })
        .catch((err) => {
          setList({
            totalInstalledMembers: 0,
            totalMembers: 0,
            groups:[],
          })
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dateRange]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <PageContainer>
      <PageTitle>
        <PageHeaderTitle>
          <span>Members</span>
        </PageHeaderTitle>
        <DateRangePicker
          value={dateRange}
          onChange={([startDate, endDate]) => {
            updateURL({
              start_date: startDate?.unix().toString(),
              end_date: endDate?.unix().toString(),
            });
          }}
        />
      </PageTitle>
      <PageContentContainer>
        <TableHeader>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={() => setCreateDialogOpen(true)}
          >
            New group
          </Button>
        </TableHeader>
        <GroupDetailTable
          group={{ subGroups: list } as Group}
          loading={loading}
          onReload={() => fetchData()}
        />
      </PageContentContainer>
      {companyInfo?.id && (
        <CreateOrUpdateGroupDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          onSuccess={() => {
            if (companyInfo?.id) {
              getDataGroups(companyInfo.id).then((data) => {
                if (data) {
                  dispatch(GroupsActions.setDataGroup(data));
                }
              });
              fetchData();
            }
          }}
          companyId={companyInfo?.id}
        />
      )}
    </PageContainer>
  );
}
