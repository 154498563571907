import { DateRange } from '@mui/lab';
import moment, { Moment } from 'moment';
import {  useMemo } from 'react';
import useQuery from './use-query';

export function useDateRangeQuery() {
  const query = useQuery();
  

  return useMemo(() => {
    const startDate = query.start_date
      ? +(query.start_date as string) * 1000
      : null;
    const endDate = query.end_date ? +(query.end_date as string) * 1000 : null;
    return [
      startDate ? moment(startDate): moment().startOf('isoWeek') ,
      endDate ? moment(endDate): moment().startOf('day') ,
    ] as DateRange<Moment>;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.start_date, query.end_date]);
}
