import { Dialog } from '@mui/material';
import {
  DialogBox,
  StyledCheckCircleIcon,
  StyledSpanTypograph,
} from './style';
import * as React from 'react';
import Box from '@mui/material/Box';
type Props = {
  open: boolean;
  onClose: () => void;
  data: {
    title: string;
    example: string;
    itemList: string[];
  };
};

function PopupHelp(props: Props) {
  return (
    <>
      <Dialog onClose={props?.onClose} open={props?.open} maxWidth="md">
        <DialogBox>
          <StyledSpanTypograph gutterBottom>
            {props?.data?.title}
          </StyledSpanTypograph>
          <StyledSpanTypograph
            sx={{
              margin: '10px 0px',
            }}
          >
            {props?.data?.example}
          </StyledSpanTypograph>
          <Box>
            {props?.data?.itemList.map((item) => {
              return (
                <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                  <StyledCheckCircleIcon />
                  <StyledSpanTypograph>{item}</StyledSpanTypograph>
                </Box>
              );
            })}
          </Box>
        </DialogBox>
      </Dialog>
    </>
  );
}
export default PopupHelp;
