import { mdiHelpCircleOutline } from '@mdi/js';
import { Grid, IconButton, SvgIcon } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PageContainer,
  PageContentContainer,
  PageHeaderTitle,
  PageTitle,
} from '../../../../components/styled';
import { AccountType } from '../../../../models/user';
import useQuery from '../../../../hooks/use-query';
import Button from '../../../../components/Button';
import ChartContainer from '../../../../components/ChartContainer';
import MPieChart from '../../../../components/charts/MPieChart';
import MLineChart from '../../../../components/charts/MLineChart';
import MBarChart from '../../../../components/charts/MBarChart';
import { hourFormat, timeFormat } from '../../../../utils/formatter';
import { createSearchQuery } from '../../../../utils/query';
import { useDateRangeQuery } from '../../../../hooks/date-range';
import DialogUserInfo from './component/dialog-user-information';
import { membersApi } from '../../../../api/members';
import { reportApi } from '../../../../api/report';
import { Box } from '@mui/system';
import Image from '../../../../components/Image';
import Select from '../../../../components/Select';
import { converMinutes, converSecondsToMinutes } from '../../../../helper/chart';
import DateRangePicker from '../../../../components/DateRangePicker';
import { MemberName } from './styled';

export default function MemberReportPage() {
  const { pathname } = useLocation();
  const naviagte = useNavigate();
  const query = useQuery();
  const [text, setText] = useState(query.keyword);
  const accountType =
    (query.account_type as AccountType) ?? AccountType.Standard;
  const [openDiaLogUserInfo, setOpenDiaLogUserInfo] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<{
    age?: number;
    aliasName?: string;
    createdAt?: string;
    displayName?: string;
    email?: string;
    photoURL?: string;
  }>({});
  const pargams = useParams();
  const memberId = useRef(pargams.id);
  const dateRange = useDateRangeQuery();
  const [eventType, setEventType] = useState<string | null>('ALL');

  const [ratioReportData, setRatioReportData] = useState<{
    goodPracticePercent: number;
    mediumPracticePercent: number;
    badPracticePercent: number;
  }>({
    goodPracticePercent: 0,
    mediumPracticePercent: 0,
    badPracticePercent: 0,
  });
  const [practicePerDateData, setPracticePerDateData] = useState<
    { min: number; duration: number }[]
  >([]);

  const [practiceTimeData, setPracticeTimeData] = useState<{
    startDate: string;
    dataList: {
      entityId?: string;
      entityName?: string;
      entityTarget?: number;
      dataByNthDay?: number[];
    }[];
  }>({
    startDate: '2022-10-25',
    dataList: [
      {
        entityId: '632d844a4bb2eebe99f9322d',
        entityName: 'linhnh',
        entityTarget: 90,
        dataByNthDay: [181, 95, 270, 360, 500, 60],
      },
    ],
  });
  const [sum, setSum] = useState<string>("");
  const [average, setAverage] = useState<string>("");

  useEffect(() => {
    document.title = 'Member Report - Mindfully Partner Portal';
    if (memberId.current) {
      membersApi.getUserInfo(memberId.current).then((res) => {
        if (res) {
          setUserInfo({
            age: res?.age || -1,
            aliasName: res.aliasName || '',
            createdAt: res.createdAt || '',
            displayName: res.displayName || '',
            email: res.email || '',
            photoURL: res.photoURL || '',
          });
        }
      });
    }
  }, []);

  function updateURL(params: {
    keyword?: string;
    page?: number;
    limit?: number;
    start_date?: string;
    end_date?: string;
  }) {
    naviagte({
      pathname,
      search: createSearchQuery({ ...query, ...params }),
    });
  }

  useEffect(() => {
    if (query.keyword !== text) {
      setText(query.keyword);
    }
    // membersApi
    //   .getGroups({
    //     accountType,
    //     limit: 20,
    //     page: +(query.page ?? 1),
    //     search: query.keyword?.toString(),
    //   })
    //   .then(setList)
    //   .catch((err) => {
    //     setList([]);
    //     // if (err?.error?.code === "ENTITY_NOT_FOUND") {
    //     // }
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }, [query.keyword, query.page, accountType]);

  useEffect(() => {
    if (memberId.current) {
      reportApi
        .getRatioReportData(memberId.current, 'member', {
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
        .then((res) => {
          setRatioReportData({
            goodPracticePercent: res.goodPracticePercent,
            mediumPracticePercent: res.mediumPracticePercent,
            badPracticePercent: res.badPracticePercent,
          });
        })
        .catch((err) => {
          console.log('Loi 1', err);
        });

      reportApi
        .getPracticeTimeOfDateData(memberId.current, 'member', {
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
        .then((res) => {
          if (res) {
            const practiceData: { min: number; duration: number }[] = [];
            res.forEach((item: number, index: number) => {
              practiceData.push({
                min: index,
                duration: converSecondsToMinutes(item),
              });
            });
            setPracticePerDateData(practiceData);
          }
        })
        .catch((err) => {
          console.log('loi 2', err);
        });
    }
  }, [dateRange]);

  useEffect(() => {
    if (memberId.current) {
      reportApi
        .getPracticeTime(memberId.current, eventType, 'member', {
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
        .then((res) => {
          setPracticeTimeData(res);
        });
    }
  }, [dateRange, eventType]);

  useEffect(() => {
    if(practiceTimeData && (practiceTimeData.dataList[0].dataByNthDay?.length || 0 ) > 0){
      let sumValue = 0;
      const length = practiceTimeData.dataList[0].dataByNthDay?.length || 1;
      practiceTimeData.dataList[0].dataByNthDay?.forEach((item) => {
        sumValue += item;
      })
      setSum(converMinutes(sumValue));
      setAverage(converMinutes(sumValue/length));
    }
  },[practiceTimeData]);

  function getPracticeDate(startDate: any, index: number) {
    const date = new Date(startDate);
    date.setDate(new Date(startDate).getDate() + index);
    return `${date.getDate()}-${date?.getMonth() + 1}-${date?.getFullYear()}`;
  }
  function converData(data: {
    startDate?: string;
    dataList: {
      entityId?: string;
      entityName?: string;
      entityTarget?: number;
      dataByNthDay?: number[];
    }[];
  }) {
    if (data?.dataList[0]?.dataByNthDay) {
      return data?.dataList[0]?.dataByNthDay.map((item: any, index: number) => {
        return {
          date: getPracticeDate(data.startDate, index),
          value: converSecondsToMinutes(item),
        };
      });
    }
  }

  return (
    <PageContainer>
      <PageTitle>
        <PageHeaderTitle>
          <span>Members</span>
          <span className="secondary-title mh-8">| Report</span>
        </PageHeaderTitle>
        <DateRangePicker
          value={dateRange}
          onChange={([startDate, endDate]) => {
            updateURL({
              start_date: startDate?.unix().toString(),
              end_date: endDate?.unix().toString(),
            });
          }}
        />
      </PageTitle>
      <PageContentContainer>
        {userInfo?.email && (
          <MemberName>
            {userInfo?.aliasName || userInfo?.displayName || 'No Name'}
            <span className="email-text">({userInfo?.email})</span>
          </MemberName>
        )}
        <DialogUserInfo
          userInfo={userInfo}
          open={openDiaLogUserInfo}
          onClose={() => setOpenDiaLogUserInfo(false)}
        />
        <Button
          color="success"
          size="small"
          style={{
            textTransform: 'uppercase',
            fontWeight: 600,
          }}
          onClick={() => setOpenDiaLogUserInfo(true)}
        >
          (View user infomation)
        </Button>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <ChartContainer
              minHeight={350}
              header={
                <>
                  <span>Ratio of practice time with Mindful Timer</span>
                  <IconButton size="small">
                    <SvgIcon fontSize="small">
                      <path d={mdiHelpCircleOutline} />
                    </SvgIcon>
                  </IconButton>
                </>
              }
            >
              {ratioReportData.goodPracticePercent ||
              ratioReportData.mediumPracticePercent ||
              ratioReportData.badPracticePercent ? (
                <MPieChart
                  data={[
                    {
                      label: 'Completed',
                      value: ratioReportData.goodPracticePercent,
                      color: '#14ADA6',
                    },
                    {
                      label: 'Half-completed',
                      value: ratioReportData.mediumPracticePercent,
                      color: '#f6cd61',
                    },
                    {
                      label: 'Uncompleted',
                      value: ratioReportData.badPracticePercent,
                      color: '#fe8a71',
                    },
                  ]}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '50px',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    className="m-16"
                    style={{ width: 128, height: 128 }}
                    src={`${process.env.PUBLIC_URL}/assets/images/box.png`}
                  />
                  <div
                    style={{
                      marginBottom: 16,
                      color: 'gray',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    No data
                  </div>
                </Box>
              )}
            </ChartContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <ChartContainer
              minHeight={350}
              header={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <span>Practice time with</span>
                    <Select
                      placeholder="Select target"
                      sx={{
                        minWidth: 180,
                        margin: '0px 8px 0px',
                      }}
                      fullWidth={false}
                      onChange={(option) => {
                        if (option?.value) {
                          setEventType(option?.value as string);
                        }
                      }}
                      value={eventType || ''}
                      options={[
                        {
                          label: 'ALL',
                          value: 'ALL',
                        },
                        {
                          label: 'Timer',
                          value: 'timer',
                        },
                        {
                          label: 'Sound',
                          value: 'music_sound',
                        },
                        {
                          label: 'Breathe Exercise',
                          value: 'breathe_exercise',
                        },
                        {
                          label: 'Guide',
                          value: 'guide_meditation',
                        },
                      ]}
                    />

                    <IconButton size="small">
                      <SvgIcon fontSize="small">
                        <path d={mdiHelpCircleOutline} />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </>
              }
              footer={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      color: '#757575',
                      fontSize: '12px',
                      fontWidth: '500',
                    }}
                  >
                    <span style={{ margin: '0 40px 0px 4px' }}>
                      {' '}
                      AVERAGE: {average}
                    </span>
                    <span> SUM: {sum}</span>
                  </Box>
                </>
              }
            >
              {(practiceTimeData?.dataList?.length || 0) > 0 &&
                practiceTimeData?.dataList && (
                  <>
                    {(practiceTimeData?.dataList?.[0]?.dataByNthDay?.length ||
                      0) > 0 ? (
                      <>
                        <MLineChart
                          indexKey="date"
                          yTickFormatter={(m) => m + 'm'}
                          references={[
                            {
                              key: 'target',
                              color: '#14ADA6',
                              value: converSecondsToMinutes(
                                practiceTimeData.dataList[0].entityTarget || 0
                              ),
                              label: `(target: ${converMinutes(
                                practiceTimeData.dataList[0].entityTarget || 0
                              )})`,
                            },
                          ]}
                          lines={[
                            {
                              key: 'value',
                              color: '#14ADA6',
                            },
                          ]}
                          data={converData(practiceTimeData) || []}
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <Image
                          className="m-16"
                          style={{ width: 128, height: 128 }}
                          src={`${process.env.PUBLIC_URL}/assets/images/box.png`}
                        />
                        <div
                          style={{
                            marginBottom: 16,
                            color: 'gray',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          No data
                        </div>
                      </Box>
                    )}
                  </>
                )}
            </ChartContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartContainer
              minHeight={350}
              header={
                <>
                  <span>Practice Times of Day</span>
                  <IconButton size="small">
                    <SvgIcon fontSize="small">
                      <path d={mdiHelpCircleOutline} />
                    </SvgIcon>
                  </IconButton>
                </>
              }
            >
              <MBarChart
                indexKey="min"
                tooltipValueFormatter={(m) => m + ' mins'}
                tooltipLabelFormatter={timeFormat}
                xTickFormatter={(v) => hourFormat(v)}
                yTickFormatter={(m) => m + 'm'}
                bars={[
                  {
                    key: 'duration',
                    color: '#14ADA6',
                  },
                ]}
                //
                data={practicePerDateData}
              />
            </ChartContainer>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <ChartContainer minHeight={350}></ChartContainer>
          </Grid> */}
        </Grid>
      </PageContentContainer>
    </PageContainer>
  );
}
