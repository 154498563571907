import { Moment } from 'moment';
import { authRequest } from './base';

const getRatioReportData = (
    id: string,
    entityType: string,
    query?: {
      startDate?: Moment | null;
      endDate?: Moment | null;
    }
  ) => {
    return authRequest<any>({
      url: `dashboard/ratio-of-practiced-time-report`,
      query: query
        ? {
            startDateStr: query.startDate?.format('l'),
            endDateStr: query.endDate?.format('l'),
            entityId: id,
            entityType: entityType,
          }
        : undefined,
      method: 'GET',
    });
  };


  const getPracticeTimeOfDateData = (
    id: string,
    entityType: string,
    query?: {
      startDate?: Moment | null;
      endDate?: Moment | null;
    }
  ) => {
   
    return authRequest<any>({
      url: `dashboard/practice-time-of-date`,
      query: query
        ? {
            startDateStr: query.startDate?.format('l'),
            endDateStr: query.endDate?.format('l'),
            entityId: id,
            entityType: entityType,
          }
        : undefined,
      method: 'GET',
    });
  };

  const getSummaryReportByMembersDetailData = (
    key: string,
    entityType: string,
    entityId: string,
    dayIndex?: number,

  ) => {
    return authRequest<any>({
      url: `dashboard/summary-report-by-member-in-detail`,
      query: {
            entityId: entityId,
            entityType: entityType,
            _key: key,
            dayIndex: dayIndex || 0,
          },
        
      method: 'GET',
    });
  };

  const getSummaryReportByMembersData = (
    companyId: string,
    targetSeconds?: number,
    query?: {
      startDate?: Moment | null;
      endDate?: Moment | null;
    }
  ) => {
    return authRequest<any>({
      url: `dashboard/summary-report-by-members`,
      query: query
        ? {
            startDateStr: query.startDate?.format('l'),
            endDateStr: query.endDate?.format('l'),
            companyId: companyId,
            targetSeconds: targetSeconds,
          }
        : undefined,
      method: 'GET',
    });
  };


  const getPracticeTime = (
    id: string,
    eventType?: string | null,
    entityType?: string,
    query?: {
      startDate?: Moment | null;
      endDate?: Moment | null;
    }
  ) => {

    if(eventType) {
      return authRequest<any>({
        url: `dashboard/practice-time`,
        query: query
          ? {
              startDateStr: query.startDate?.format('l'),
              endDateStr: query.endDate?.format('l'),
              entityId: id,
              eventType : eventType,
              entityType: entityType,
            }
          : undefined,
        method: 'GET',
      });
    }else{

      return authRequest<any>({
        url: `dashboard/practice-time`,
        query: query
          ? {
              startDateStr: query.startDate?.format(),
              endDateStr: query.endDate?.format(),
              entityId: id,
              entityType: entityType,
            }
          : undefined,
        method: 'GET',
      });
    }
  };
  export const reportApi = {
    getSummaryReportByMembersDetailData,
    getRatioReportData,
    getSummaryReportByMembersData,
    getPracticeTimeOfDateData,
    getPracticeTime,
  };