export function converSecondsToMinutes(seconds: number){ 
         return (seconds/60);
}

export function converMinutes(seconds: number){
    
    const m = Math.floor(seconds/60);
    const s = Math.floor(seconds - m*60);
    if(s){
        return `${m}m ${s}s`;
    }
    return `${m}m`;
}

export function converMinutesToString(minutes: number){
    const seconds = minutes*60;
    return converMinutes(seconds);
}