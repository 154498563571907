import { Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const MedioButton = styled(Button)({
  padding: "5px 16px",
  textTransform: "none",
  fontFamily: "inherit",
  minWidth: "90px"

});
export const StyledTypograph = styled(Typography)({
    fontFamily: "quicksand",
    fontSize: "17px",
    margin: "0px 0px 20px 0px",
    fontWeight: "600"
})


export const StyledLabelTypograph = styled(Typography)({
  fontFamily: "quicksand",
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "none",
  marginBottom: "10px"
})

export const StyledSpanTypograph = styled(Typography)({
  fontFamily: "quicksand",
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "none",
})

export const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  fontFamily: "quicksand",
  fontSize: "20px",
  marginRight: "5px",
  fontWeight: "500",
  color: "rgb(20, 173, 166)"
})


export const DialogBox = styled(Box)({
    padding: "40px 25px 30px 25px",
    width: "360px",
    maxHeight: "450px"
})
