import { Company } from "../../models/company";
import Enum from "../../utils/enum";
import { typedAction } from "../../.redux/typed-action";

export const CompanyActionEnum = {
  SET_COMPANY: "company/SET_COMPANY",
  RESET: "RESET",
} as const;

export type CompanyActionEnum = Enum<typeof CompanyActionEnum>;

function setCompany(company: Company) {
  return typedAction(CompanyActionEnum.SET_COMPANY, company);
}

export type CompanyActionTypes =
  | ReturnType<typeof setCompany>
  | { type: typeof CompanyActionEnum.RESET };

export default { setCompany };
