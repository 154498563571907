import { useEffect, useState, useMemo } from 'react';
import {
  PageContainer,
  PageContentContainer,
  PageHeaderTitle,
  PageTitle,
} from '../../../components/styled';
import DateRangePicker from '../../../components/DateRangePicker';
import { Grid, IconButton, SvgIcon } from '@mui/material';
import ChartContainer from '../../../components/ChartContainer';
import { mdiHelpCircleOutline } from '@mdi/js';
import MPieChart from '../../../components/charts/MPieChart';
import MBarChart from '../../../components/charts/MBarChart';
import { hourFormat, timeFormat } from '../../../utils/formatter';
import useQuery from '../../../hooks/use-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { createSearchQuery } from '../../../utils/query';
import { useDateRangeQuery } from '../../../hooks/date-range';
import PopoverSelect from '../../../components/Select/popover-select';
import { reportApi } from '../../../api/report';
import { Box } from '@mui/system';
import Image from '../../../components/Image';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { converSecondsToMinutes } from '../../../helper/chart';
import DialogMember from './dialog-member';
import MBarMemberChart from '../../../components/charts/MBarMemberChart';
import { companyApi } from '../../../api/company';
import { Group } from '../../../models/members';
import PopupHelp from './popup-help';

const help1: {
  title: string;
  example: string;
  itemList: string[];
} = {
  title:
    'Phần trăm số người thực tập 15p trở lên mỗi ngày trong khoảng thời gian đã chọn ',
  example: 'Ví dụ: Khoảng thời gian chọn là 30 ngày.',
  itemList: [
    'Good member: là những member đã thực tập đầy đủ trong 30 ngày (100% khoảng thời gian đã chọn)',
    'Medium member: là những member đã thực tập từ 15 đến 29 ngày (> 50% khoảng thời gian đã chọn)',
    'Bad member: là những member đã thực tập dưới 15 ngày (< 50% khoảng thời gian đã chọn)',
  ],
};

const help2: {
  title: string;
  example: string;
  itemList: string[];
} = {
  title:'Thống kê số lượng người theo từng nhóm thực tập đủ và chưa đủ thời lượng mục tiêu mỗi ngày',
  example: 'Ví dụ: Thời lượng mục tiêu của nhóm A là 15 phút mỗi ngày',
  itemList: [
    "Good member: Là những người có tổng thời gian thực tập trong ngày từ 15 phút trở lên (>= 100% thời lượng mục tiêu).",
    "Medium member: Là những người có tổng thời gian thực tập trong ngày từ 7,5 đến 15 phút (>= 50% thời lượng mục tiêu).",
    "Bad member: Là những người có tổng thời gian thực tập trong ngày nhỏ hơn 7,5 phút (< 50% thời lượng mục tiêu).",
    
  ],
};

type PracticeOfDateType = {
  min: number | string;
  index?: number;
  duration: number;
};

type PracticeByDayType = {
  day: number | string;
  index?: number;
  good: number;
  medium: number;
  bad: number;
};
type PracticeGroupsType = {
  badMemberCount: number;
  goodMemberCount: number;
  mediumMemberCount: number;
  groupName: string;
  groupId: string;
  dataByDay: number[][];
};

type PracticeSummaryReportType = {
  key?: string;
  startDate?: string;
  groups?: PracticeGroupsType[];
};

type QueryMemberReportType = {
  key: string;
  entityType: string;
  entityId: string;
  dayIndex?: number;
};

export default function DashboardPage() {
  const query = useQuery();
  const naviagte = useNavigate();
  const { pathname } = useLocation();
  const dateRange = useDateRangeQuery();
  
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openPopupHelpPie, setOpenPopupHelpPie] = useState<boolean>(false);
  const [queryMemberReport, setQueryMemberReport] =
    useState<QueryMemberReportType>({
      key: '',
      entityId: '',
      entityType: '',
    });
  const companyInfo = useSelector((state: RootState) => state.company.company);
  const dataGroup = useSelector((state: RootState) => state.group.dataGroup);
  const [groupId, setGroupId] = useState<string>(companyInfo?.id || '');
  const [title, setTitle] = useState<string>('');
  const [nameGroup, setNameGroup] = useState<string>('');
  const [value, setValue] = useState<Group | undefined>();
  const [textHelp, setTextHelp] = useState<{
    title: string;
    example: string;
    itemList: string[];
  }>({
    title: '',
    example: '',
    itemList: [],
  });
  const [members, setMembers] = useState<{
    good: number;
    medium: number;
    bad: number;
  }>({
    good: 0,
    medium: 0,
    bad: 0,
  });
  const [practiceSummaryReportData, setPracticeSummaryReportData] =
    useState<PracticeSummaryReportType>({
      startDate: '',
      groups: [],
      key: '',
    });
  const [ratioReportData, setRatioReportData] = useState<{
    goodPracticePercent: number;
    mediumPracticePercent: number;
    badPracticePercent: number;
  }>({
    goodPracticePercent: 0,
    mediumPracticePercent: 0,
    badPracticePercent: 0,
  });
  const [practicePerDateData, setPracticePerDateData] = useState<
    PracticeOfDateType[]
  >([]);

  function updateURL(params: {
    keyword?: string;
    page?: number;
    limit?: number;
    start_date?: string;
    end_date?: string;
    target?: number;
    team?: number;
  }) {
    naviagte({
      pathname,
      search: createSearchQuery({ ...query, ...params }),
    });
  }


  useEffect(() => {
    document.title = 'Dashboard - Mindfully Partner Portal';
    if (companyInfo?.id && (JSON.stringify(dataGroup) != "[]" && dataGroup)){
      companyApi
        .getGroupDetail(companyInfo?.id, `${dataGroup[0].value}`, {
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
        .then((res) => {
            setValue(res);
            setGroupId(res?.id);
            setNameGroup(res?.name || "");
        })
        .catch((err) => {
          setValue(undefined);
        });
    }
  }, [dataGroup]);

  useEffect(() => {
    let entityType: string;
    if (groupId !== companyInfo?.id) {
      entityType = 'group';
    } else {
      entityType = 'company';
    }

    reportApi
      .getPracticeTimeOfDateData(groupId, entityType, {
        startDate: dateRange[0],
        endDate: dateRange[1],
      })
      .then((res) => {
        if (res) {
          const practiceData: { min: number; duration: number }[] = [];
          res.forEach((item: number, index: number) => {
            practiceData.push({
              min: index,
              duration: converSecondsToMinutes(item),
            });
          });
          setPracticePerDateData(practiceData);
        }
      })
      .catch((err) => {
        console.log('loi 2', err);
      });
  }, [dateRange]);

  useEffect(() => {
    if (companyInfo?.id) {
      reportApi
        ?.getSummaryReportByMembersData(companyInfo?.id, 900, {
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
        .then((data: any) => {
          if (data) {
            setRatioReportData({
              goodPracticePercent: data?.goodMemberCount,
              mediumPracticePercent: data?.mediumMemberCount,
              badPracticePercent: data?.badMemberCount,
            });

            setPracticeSummaryReportData({
              ...practiceSummaryReportData,
              startDate: data?.startDate,
              groups: data?.groups,
              key: data?._key,
            });
            // const dataByDate: PracticeByDayType[] = data?.groups[0].dataByDay.map((item:any, idx:number) => {
            //   return {
            //     min: getPracticeDate(data?.startDate,idx ),
            //     duration: converSecondsToMinutes(item[0] + Math.floor(Math.random() * 900) + 100),
            //   }
            // })
            // setPracticeByDayData(dataByDate);
          }
        });
    }
  }, [dateRange]);

  function convertDataFromGroupsWithId() {
    const group: PracticeGroupsType | undefined =
      practiceSummaryReportData?.groups?.filter(
        (group) => group?.groupId == groupId
      )[0];

    if (group) {
      setMembers({
        ...members,
        good: group?.goodMemberCount,
        medium: group?.mediumMemberCount,
        bad: group?.badMemberCount,
      });
      const dataByDay: PracticeByDayType[] = group?.dataByDay?.map(
        (item: any, idx: number) => {
          return {
            day: getPracticeDate(practiceSummaryReportData?.startDate, idx),
            index: idx,
            good: item[0],
            medium: item[1],
            bad: item[2],
          };
        }
      );
      return dataByDay;
    }
  }

  const convertDataFromGroupsWithIdMemo = useMemo(
    () => convertDataFromGroupsWithId(),
    [practiceSummaryReportData, groupId]
  );
  function getPracticeDate(startDate: any, index: number) {
    let date = new Date(startDate);
    date.setDate(new Date(startDate).getDate() + index);
    return `${date.getDate()}-${date?.getMonth() + 1}-${date?.getFullYear()}`;
  }

  return (
    <PageContainer>
      <PageTitle>
        <PageHeaderTitle>
          <span>Dashboard</span>
        </PageHeaderTitle>
        <DateRangePicker
          value={dateRange}
          onChange={([startDate, endDate]) => {

            updateURL({
              start_date: startDate?.unix().toString(),
              end_date: endDate?.unix().toString(),
            });
          }}
        />
      </PageTitle>
      <PageContentContainer>
        <Grid container spacing={1}>
          <Grid
            sx={{
              mt: '16px',
            }}
            item
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            mt: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <ChartContainer
              minHeight={400}
              header={
                <>
                  <span>Ratio of practiced members</span>
                  <IconButton
                    onClick={() => {
                      setTextHelp(help1);
                      setOpenPopupHelpPie(true);
                    }}
                    size="small"
                  >
                    <SvgIcon fontSize="small">
                      <path d={mdiHelpCircleOutline} />
                    </SvgIcon>
                  </IconButton>
                </>
              }
            >
              {ratioReportData.goodPracticePercent ||
              ratioReportData.mediumPracticePercent ||
              ratioReportData.badPracticePercent ? (
                <MPieChart
                  onClick={() => {
                    setTitle('Practime company');
                    setQueryMemberReport({
                      ...queryMemberReport,
                      key: practiceSummaryReportData?.key || '',
                      entityType: 'company',
                      entityId: companyInfo?.id || '',
                      dayIndex: 0,
                    });
                    setOpenDialog(true);
                  }}
                  data={[
                    {
                      label: 'Good member',
                      value: ratioReportData.goodPracticePercent,
                      color: '#14ADA6',
                    },
                    {
                      label: 'Medium member',
                      value: ratioReportData.mediumPracticePercent,
                      color: '#f6cd61',
                    },
                    {
                      label: 'Bad member',
                      value: ratioReportData.badPracticePercent,
                      color: '#fe8a71',
                    },
                  ]}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '50px',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    className="m-16"
                    style={{ width: 128, height: 128 }}
                    src={`${process.env.PUBLIC_URL}/assets/images/box.png`}
                  />
                  <div
                    style={{
                      marginBottom: 16,
                      color: 'gray',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    No data
                  </div>
                </Box>
              )}
            </ChartContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartContainer
              minHeight={400}
              header={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <span style={{ marginRight: '10px' }}>
                    Practiced members by
                    </span>
                    <PopoverSelect
                      isUseForReport
                      path={value?.path}
                      nodeId={value?.id}
                      label={value?.name}
                      onChangeName={(nameGroup: string) => {
                        setNameGroup(nameGroup);
                      }}
                      onChangeId={(id: string) => setGroupId(id)}
                    ></PopoverSelect>

                    <IconButton onClick={() => {
                      setTextHelp(help2);
                      setOpenPopupHelpPie(true);
                    }} size="small">
                      <SvgIcon fontSize="small">
                        <path d={mdiHelpCircleOutline} />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </>
              }
              footer={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#757575',
                      fontSize: '12px',
                      fontWidth: '500',
                      marginTop: '5px',
                      padding: "0 30px",
                    }}
                  >
                    <span> Good members: {members?.good}</span>
                    <span> Medium members: {members?.medium}</span>
                    <span> Bad members: {members.bad}</span>
                    <span>
                      {' '}
                      SUM: {members.good + members.medium + members.bad}{' '}
                    </span>
                  </Box>
                </>
              }
            >
              {(practiceSummaryReportData?.groups?.length || 0) > 0 && (
                <>
                  {(practiceSummaryReportData?.groups?.length || 0) > 0 ? (
                    <MBarMemberChart
                      indexKey="day"
                      tooltipValueFormatter={(m) => m + ' mins'}
                      tooltipLabelFormatter={(v) => v}
                      xTickFormatter={(v) => v}
                      yTickFormatter={(m) => m}
                      onClick={(data) => {
                        
                        setTitle(`Practice time of ${nameGroup}`);
                        setQueryMemberReport({
                          ...queryMemberReport,
                          key: practiceSummaryReportData?.key || '',
                          entityType: 'group',
                          entityId: groupId,
                          dayIndex: data?.index,
                        });
                        setOpenDialog(true);
                      }}
                      bars={[
                        {
                          key: 'good',
                          label: "Good members",
                          color: '#14ADA6',
                        },
                        {
                          key: 'medium',
                          color: '#f6cd61',
                        },
                        {
                          key: 'bad',
                          color: '#fe8a71',
                        },
                      ]}
                      //
                      data={convertDataFromGroupsWithIdMemo || []}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Image
                        className="m-16"
                        style={{ width: 128, height: 128 }}
                        src={`${process.env.PUBLIC_URL}/assets/images/box.png`}
                      />
                      <div
                        style={{
                          marginBottom: 16,
                          color: 'gray',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}
                      >
                        No data
                      </div>
                    </Box>
                  )}
                </>
              )}
            </ChartContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartContainer
              minHeight={400}
              header={
                <>
                  <span>Practice Times of Day</span>
                  <IconButton size="small">
                    <SvgIcon fontSize="small">
                      <path d={mdiHelpCircleOutline} />
                    </SvgIcon>
                  </IconButton>
                </>
              }
            >
              <MBarChart
                indexKey="min"
                tooltipValueFormatter={(m) => m + ' mins'}
                tooltipLabelFormatter={timeFormat}
                xTickFormatter={(v) => hourFormat(v)}
                yTickFormatter={(m) => m + 'm'}
                bars={[
                  {
                    key: 'duration',
                    color: '#14ADA6',
                  },
                ]}
                //
                data={practicePerDateData}
              />
            </ChartContainer>
          </Grid>
        </Grid>
      </PageContentContainer>
      <DialogMember
        query={queryMemberReport}
        title={title}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
      <PopupHelp
        data={textHelp}
        onClose={() => setOpenPopupHelpPie(false)}
        open={openPopupHelpPie}
      />
    </PageContainer>
  );
}
