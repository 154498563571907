import styled from '@emotion/styled';
import { TableCell, TablePagination, TableRow } from '@mui/material';

export const StyledTableCell = styled(TableCell)`
  font-family: quicksand;
  &.MuiTableCell-head {
    font-weight: 600;
    color: '#757575';
  }
`;

export const StyledTableRow = styled(TableRow)`
  .MuiTablePagination-displayedRows {
    font-family: quicksand;
  }
  ${({ onClick }) => (onClick ? `cursor: pointer;` : '')};
  :hover {
    background-color: rgba(240, 248, 255, 0.5);
  }
`;

export const StyledTablePagination = styled(TablePagination)`
font-size: "100px";
.MuiTablePagination-root {
  font-family: quicksand;
  font-size: "40px";

};
.MuiTablePagination-displayedRows{
  font-size: "40px !important";
  color: "red";
};
.MuiTablePagination-selectLabel{
  font-size: "40px";
};
.MuiTablePagination-menuItem {
  font-size: "40px";
};
`;
