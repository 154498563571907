import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Formatter,
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { CurveType } from 'recharts/types/shape/Curve';
import { Container } from './styled';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { converMinutesToString } from '../../helper/chart';
interface LineOption {
  key: string;
  color?: string;
  type?: CurveType;
}

type Props<T = { [key: string]: any }> = {
  indexKey: string;
  data: T[];
  lines: LineOption[];
  references?: {
    key: string;
    color?: string;
    value: string | number;
    label?: string;
  }[];
  xTickFormatter?: (value: any) => string;
  yTickFormatter?: (value: any) => string;
  tooltipValueFormatter?: Formatter<ValueType, NameType>;
  tooltipLabelFormatter?: (value: any) => string;
};

export default function MLineChart<T>(props: Props<T>) {
  const [opacity, setOpacity] = useState<any>({});
  useEffect(() => {
    if (props.lines) {
      const opa: any = {};
      props.lines.forEach((item, index) => {
        console.log(item.key);

        opa[item.key] = '1';
      });
      setOpacity(opa);
    }
  }, [props.lines]);

  function handleClick(value: any, dataKey: any) {
    if (opacity[value] == '0.05') {
      setOpacity({ ...opacity, [value]: '1' });
    } else {
      setOpacity({ ...opacity, [value]: '0.05' });
    }
  }

  return (
    <Container width="100%" height="100%">
      <LineChart
        // width={500}
        // height={300}
        data={props.data}
      >
        <CartesianGrid strokeDasharray="2 2" vertical={false} />
        <XAxis
          dataKey={props.indexKey}
          tickLine={false}
          tick={false}
          tickFormatter={props.xTickFormatter}
        />

        <YAxis
          orientation="right"
          tickLine={false}
          tickFormatter={props.yTickFormatter}
          width={40}
        />
        {props.references?.map((line) => {
          return (
            <ReferenceLine
              key={'ref-' + line.key}
              stroke={line.color}
              strokeDasharray="4 4"
              opacity={opacity[line.key]}
              y={line.value}
              label={
                <Label
                  position="insideLeft"
                  fill={line.color}
                  fontSize={11}
                  fontFamily="quicksand"
                  transform="translate(0, 8)"
                  opacity={opacity[line.key]}
                >
                  {line.label}
                </Label>
              }
            />
          );
        })}
        {/* <Tooltip
          labelFormatter={props.tooltipLabelFormatter}
          formatter={props.tooltipValueFormatter}
          viewBox = {{ x: 200, y: 200, width: 400, height: 400 }}
          contentStyle={{
            marginLeft: "30px",
            marginRight: "30px",
            borderWidth: 0,
            borderRadius: 4,
            boxShadow: '0 0 6px rgba(0,0,0,0.16)',
            outline: 'none',
          }}
        /> */}
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend onClick={handleClick} /> */}

        {(props?.references?.length || 0) > 1 && (
          <Legend
            onClick={(value, dataKey) => {
              handleClick(value, dataKey);
            }}
            content={renderLegend}
          />
        )}
        {props.lines.map((line) => {
          return (
            <Line
              animationDuration={1000}
              key={'line-' + line.key}
              type={line.type}
              dataKey={line.key}
              stroke={line.color}
              activeDot={{ r: 4 }}
              id={line.key}
              dot={{ r: 0 }}
              strokeOpacity={opacity[line.key]}
              strokeWidth={2}
              // onMouseEnter={() => handleOnMouseEnter(line.key)}
              // onMouseLeave={() => handleOnMouseLeave(line.key)}
            />
          );
        })}
      </LineChart>
    </Container>
  );
}

const renderLegend = (props: any) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        {props.payload.map((entry: any, index: any) => (
          <Box
            onClick={() => {
              props.onClick(entry.value);
            }}
            sx={{
              backgroundColor:
                entry.payload.strokeOpacity == '1' ? entry.color : 'none',
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              minWidth: '70px',
              alignContent: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              margin: '2px',
              padding: '0px 4px',
              height: '22px',
              borderRadius: '4px',
              border:
                entry.payload.strokeOpacity == '1'
                  ? 'none'
                  : `0.2px solid ${entry.color}`,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                fontSize: '11px',
                marginRight: '5px',
                color:
                  entry.payload.strokeOpacity == '1' ? '#fff' : entry.color,
              }}
            />
            <p
              style={{
                fontSize: '11px',
                lineHeight: '0px',
                color:
                  entry.payload.strokeOpacity == '1' ? '#fff' : entry.color,
              }}
              key={`item-${index}`}
            >
              {entry.value}
            </p>
          </Box>
        ))}
      </Box>
    </>
  );
};

const CustomTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: '#fff',
          margin: '0 30px',
          borderWidth: 0,
          borderRadius: 2,
          boxShadow: '0 0 6px rgba(0,0,0,0.16)',
          outline: 'none',
          padding: '10px',
        }}
      >
        <p>{props.label} </p>
        {props.payload.map((item: any, index: number) => {
          return (
            <p style={{ color: item.color, opacity: item.strokeOpacity * 4 }}>
              {item.dataKey}: {converMinutesToString(item.value)}
            </p>
          );
        })}
      </Box>
    );
  }

  return null;
};
