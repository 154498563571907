import { Avatar, Dialog, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyledTextTypography, StyledTypography } from './styled';

type DialogUserInfoProps = {
  open: boolean;
  onClose: () => void;
  userInfo: {
    age?: number;
    aliasName?: string;
    createdAt?: string;
    displayName?: string;
    email?: string;
    photoURL?: string;
  }
};
function DialogUserInfo(props: DialogUserInfoProps) {
  const [open, setOpen] = useState<boolean>(props.open);
  const [userInfo, setUserInfo] = useState<{
    age?: number;
    aliasName?: string;
    createdAt?: string;
    displayName?: string;
    email?: string;
    photoURL?: string;
  }>(props?.userInfo);

  const pargams = useParams();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if(props?.userInfo?.email){
        setUserInfo(props?.userInfo);
    }
  }, [props.userInfo]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          props.onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            width: '400px',
            p: '40px 20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar
              alt="Avatar"
              src={userInfo.photoURL}
              sx={{ width: 100, height: 100 }}
            />
            <Box sx={{ ml: '40px' }}>
              <StyledTypography
                variant="h3"
                sx={{ fontSize: '16px', fontWeight: '600' }}
              >
                {userInfo?.displayName ||
                  userInfo?.aliasName ||
                  userInfo?.email}
              </StyledTypography>
              <StyledTypography sx={{ color: 'gray', mt: '8px' }}>
                ID: {pargams?.id}
              </StyledTypography>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={0}>
              {userInfo?.displayName && (
                <Grid item xs={12}>
                  <StyledTypography>Display Name</StyledTypography>
                  <StyledTextTypography align="center" variant="h3">
                    {userInfo.displayName}
                  </StyledTextTypography>
                </Grid>
              )}
              {userInfo?.aliasName && (
                <Grid item xs={12}>
                  <StyledTypography>Alias Name</StyledTypography>
                  <StyledTextTypography align="center" variant="h3">
                    {userInfo?.aliasName}
                  </StyledTextTypography>
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledTypography>Email</StyledTypography>
                <StyledTextTypography align="center" variant="h3">
                  {userInfo?.email}
                </StyledTextTypography>
              </Grid>
              {(userInfo?.age || 0) > 0 && (
                <Grid item xs={12}>
                  <StyledTypography>Age</StyledTypography>
                  <StyledTextTypography align="center" variant="h3">
                    {userInfo?.age}
                  </StyledTextTypography>
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledTypography>CreatedAt</StyledTypography>
                <StyledTextTypography align="center" variant="h3">
                  {userInfo?.createdAt}
                </StyledTextTypography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
export default DialogUserInfo;
